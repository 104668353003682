@mixin split-background($color1, $color2){
    background: $color1;
    background: -moz-linear-gradient(left, $color1 0%, $color1 50%, $color2 50%, $color2 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, $color1), color-stop(50%, $color1), color-stop(50%, $color2), color-stop(100%, $color2));
    background: -webkit-linear-gradient(left, $color1 0%, $color1 50%, $color2 50%, $color2 100%);
    background: -o-linear-gradient(left, $color1 0%, $color1 50%, $color2 50%, $color2 100%);
    background: -ms-linear-gradient(left, $color1 0%, $color1 50%, $color2 50%, $color2 100%);
    background: linear-gradient(to right, $color1 0%, $color1 50%, $color2 50%, $color2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2', GradientType=1 );
}
@mixin button-gradient($top : white, $bottom : rgba(220,235,248,1)) {
    background: -moz-linear-gradient(top, $top 66%, $bottom 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(66%, $top), color-stop(100%, $bottom));
    background: -webkit-linear-gradient(top, $top 66%, $bottom 100%);
    background: -o-linear-gradient(top, $top 66%, $bottom 100%);
    background: -ms-linear-gradient(top, $top 66%, $bottom 100%);
    background: linear-gradient(to bottom, $top 66%, $bottom 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$top', endColorstr='$bottom', GradientType=0 );
}
@mixin centerer {
  position: absolute;
  // top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
}
