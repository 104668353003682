.hero {
    background: #a1c5ed;
    background: -moz-linear-gradient(top, #79a6de 0%, #b6d3f2 40%, #f1f7fd 100%);
    background: -webkit-linear-gradient(top, #79a6de 0%,#b6d3f2 40%,#f1f7fd 100%);
    background: linear-gradient(to bottom, #79a6de 0%,#b6d3f2 40%,#f1f7fd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a1c5ed', endColorstr='#f1f7fd',GradientType=0 );
    overflow:hidden;
    .container {
        position:relative;
        &:after, &:before {
            z-index:2;
            content:'';
            height:278px;
            width:632px;
            position:absolute;
            bottom:-10px;
            left:-200px;
            background:transparent url('img/cloud.png') 50% 50% no-repeat;
            background-size:100%;
        }
        &:after {
            left:auto;
            right:-480px;
            bottom:-60px;
            transform: scaleX(-1);
        }
    }
    .content {
        padding:3em 0;
        position:relative;
        z-index:5;
        h1 { max-width:12em; }
        p { max-width:27em; }
    }
}

.flag {
    position:absolute;
    bottom:0;
    right:1em;
    z-index:3;
    width:45%;
}

.tile-family .row {
    float:left;
    max-width:60%;
}
.tile-family .line1:after {
    content:',';
}
.bottle-family {
    float:right;
    margin-top:-3em;
}
.product-list {
    font-size:0.75em;
    overflow:auto;
    margin-top:2em;
    li {
        width:45%;
        float:left;
        display:inline;
        margin:0 5% 1em 0;
    }
}
@media screen and (min-width:60em){
    .tile-family .line2 { display:block; }
}
@media screen and (max-width:60em){
    .bottle-family img { width:15em; margin-bottom:-2em; }
    .tile-family { text-align:center; font-size:0.8em; }
    .tile-family .line1:after {
        content:' — ';
    }
    .tile-family .row {
        float:none;
        max-width:100%;
    }
    .bottle-family {
        margin:1em auto 0;
        float:none;
    }
    .product-list li {
        width:50%;
        float:left;
        margin:0 0 1em 0;
    }
}


@media screen and (max-width:68em){
    .hero p { max-width:24.5em; }
}
@media screen and (max-width:45em){
    .hero .content { padding:2em 0; }
    .flag {
        width:70%;
        max-width:13em;
        bottom:0;
        transform:translateX(50%);
        right:50%;
    }
    .hero .container {
        padding-bottom:12em;
        text-align:center;
    }
    .hero p { max-width:30em; margin:1em auto; font-size:0.9em; }
    .hero h1 { margin:0 auto; font-size:1.9em; }
    .heb { width:68px; height:24px;}
    h2 { font-size:1.25em; }
}
@media screen and (max-width:40em){
    .flag { width:50%; }
    .hero .container { padding-bottom:40%; }
}


/* SPLIT */
.tile-contrast {
    text-align:center;
    position:relative;
    color:#fff;
    ul {
        text-align:left;
        margin:1em auto 0;
        list-style:disc;
        width:auto;
        display:inline-block;
        li {
            width:100%;
        }
    }
    .bottle-container {
        position:absolute;
        bottom:-2em;
        left:50%;
        transform:translateX(-50%);
        z-index:10;
        img {
            width:100%;
            max-width:190px;
        }
    }
    .row {
        overflow:hidden;
    }
    & .left-box, & .right-box {
        width:50%;
        float:left;
        //height:20em;
        padding:4em;
        line-height:1.5em;

        padding-bottom:12em;
        margin-bottom:-8em;
    }
    & .left-box {
        background-color:#0071ce;
        background: -moz-linear-gradient(top, #36c3fb 0%, #0ba2ee 1%, #0071ce 10%, #0066bb 100%);
        background: -webkit-linear-gradient(top, #36c3fb 0%,#0ba2ee 1%,#0071ce 10%,#0066bb 100%);
        background: linear-gradient(to bottom, #36c3fb 0%,#0ba2ee 1%,#0071ce 10%,#0066bb 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36c3fb', endColorstr='#0066bb',GradientType=0 );
    }
    & .right-box {
        background-color:#eb0029;
        background: -moz-linear-gradient(top, #fab3d6 0%, #f8558d 1%, #eb0029 10%, #d50025 100%);
        background: -webkit-linear-gradient(top, #fab3d6 0%,#f8558d 1%,#eb0029 10%,#d50025 100%);
        background: linear-gradient(to bottom, #fab3d6 0%,#f8558d 1%,#eb0029 10%,#d50025 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb0029', endColorstr='#d50025',GradientType=0 );
    }
    .contrast-header {
        font-size:1.25em;
        font-weight:600;
        max-width:15em;
        margin:0 auto;
    }
}
.tile-cta {
    text-align:center;
    padding:4em 0;
    background: #fff;
    background: -moz-linear-gradient(top, #cecece 0%, #efefef 4%, #ffffff 30%);
    background: -webkit-linear-gradient(top, #cecece 0%,#efefef 4%,#ffffff 30%);
    background: linear-gradient(to bottom, #cecece 0%,#efefef 4%,#ffffff 30%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cecece', endColorstr='#ffffff',GradientType=0 );
}
.tile-family {
    background:#fafafa;
    border-top:1px solid #f2f2f2;
    padding:2em 0;
    box-shadow:inset 0 1px 0 #fff;
    background: #fafafa;
    background: -moz-linear-gradient(top, #fafafa 0%, #dddddd 100%);
    background: -webkit-linear-gradient(top, #fafafa 0%,#dddddd 100%);
    background: linear-gradient(to bottom, #fafafa 0%,#dddddd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#dddddd',GradientType=0 );
}
@media screen and (max-width:70em){
    .tile-contrast {
        & .left-box { padding:4em 5em 12em 3em; }
        & .right-box { padding:4em 3em 12em 5em; }
        ul {
            margin-left:1em;
            li {
                width:100%;
                white-space:normal;
            }
        }
    }
}
@media screen and (max-width:60em){
    .tile-contrast {
        .contrast-header {
            max-width:100%;
            margin:0 auto;
        }
        & .left-box { padding:4em 6em 12em 1em; }
        & .right-box { padding:4em 1em 12em 6em; }
        .contrast-header {
            font-size:1em;
        }
    }
}
@media screen and (max-width:40em){
    .tile-contrast {
        & .left-box, & .right-box {
            width:auto;
            float:none;
            padding:2em;
            padding-left:14em;
            text-align:left;
            line-height:1.2em;
            margin-bottom:0;
            font-size:0.8em;
        }
        .bottle-container {
            bottom:50%;
            left:0;
            transform:translateY(50%);
        }
    }
}
@media screen and (max-width:30em){
    .tile-contrast {
        & .left-box, & .right-box {
            padding-left:8em;
        }
        .bottle-container {
            left:-1em;
            width:6.5em;
        }
    }
    .hero p { font-size:0.8em; }
    .hero h1 { font-size:1.4em; }
}