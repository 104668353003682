@viewport {
  zoom: 1.0;
  width: extend-to-zoom;
}
@-ms-viewport {
  width: extend-to-zoom;
  zoom: 1.0;
}
.container {
  /* * For IE 6/7 only * Include this rule to trigger hasLayout and contain floats. */

  *zoom: 1;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
  padding:0 1em
}
.container:after {
  clear: both;
}
.container:before,
.container:after {
  content: " ";
  display: table;
}
.row {
  /* * For IE 6/7 only * Include this rule to trigger hasLayout and contain floats. */
  *zoom: 1;
}
.row:after {
  clear: both;
}
.row:before,
.row:after {
  content: " ";
  display: table;
}
*{
	box-sizing: border-box;
}