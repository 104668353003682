#coupon {
    main {
        padding-top: 0;
        margin-top:1em;
        text-align:center;
    }
    img{
        width: 100%;
        margin:0 auto;
        max-width:26em;
        display:block;
    }
    .button {
        margin:1em auto;
        max-width:16em;
        display:block;
    }
}
